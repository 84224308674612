<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Change password
		</h1>
		<div class="row justify-start q-pb-md">
			<q-btn
				flat
				class="content-start"
				icon="keyboard_backspace"
				label="Back"
				@click="redirectDashboard"
			/>
		</div>

		<div class="row justify-center">
			<div class="col-md-8 col-lg-6 col-xl-4">
				<q-card>
					<q-card-section class="text-center">
						<q-input
							ref="old_password"
							counter
							hint="Please remember all fields are case sensitive"
							outlined
							class="q-mt-lg"
							label="Old password"
							bottom-slots
							v-model="old_password"
							:type="oPwd ? 'password' : 'text'"
							:error-message="old_password_error_message"
							:error="old_password_error"
							@focus="
								old_password_error_message = '',
								old_password_error = false
							"
						>
							<template v-slot:append>
								<q-icon
									:name="oPwd ? 'visibility_off' : 'visibility'"
									class="cursor-pointer"
									@click="oPwd = !oPwd"
								/>
							</template>
						</q-input>
						<div class="relative-position q-pt-xs q-mt-xs">
							<q-icon
								class="absolute-top-right cursor-pointer"
								size="xs"
								name="help"
							>
								<q-tooltip
									anchor="top left"
									self="bottom end"
									content-class="shadow-4 col-md-8 col-lg-6 col-xl-4"
									outlined
									content-style=" font-size: 14px;"
								>
									<ul class="text-left">
										<li>
											Your new password should be hard to guess but relatively
											easy for you to remember.
										</li>
										<li>
											We suggest creating passwords with three random words eg
											“coffeetrainfish”.
										</li>
										<li v-if="password_complexity_text_length">
											Password should be at least
											{{ password_complexity_text_length }} characters.
										</li>
										<li v-if="password_complexity_text">
											The password must contain at least:
											<ul>
												<li
													v-for="(complexity_rule, index) in password_complexity_text"
													:key="index"
												>
													{{ complexity_rule }}
												</li>
											</ul>
										</li>
									</ul>
								</q-tooltip>
							</q-icon>
							<q-input
								ref="password"
								counter
								hint="Please remember all fields are case sensitive"
								outlined
								class="q-mt-lg"
								label="Password"
								:rules="pass_save ? [] : rules"
								bottom-slots
								v-model="password"
								:type="Pwd ? 'password' : 'text'"
								:error-message="password_error_message"
								:error="password_error"
								@focus="
									password_error_message = '',
									password_error = false,
									pass_save = false
								"
							>
								<template v-slot:append>
									<q-icon
										:name="Pwd ? 'visibility_off' : 'visibility'"
										class="cursor-pointer"
										@click="Pwd = !Pwd"
									/>
								</template>
							</q-input>
						</div>
						<q-input
							ref="confirm_pass"
							counter
							hint="Please remember all fields are case sensitive"
							outlined
							class="q-mt-lg"
							label="Re-enter password"
							:rules="pass_save ? [] : rules"
							bottom-slots
							v-model="confirm_password"
							:type="cfPwd ? 'password' : 'text'"
							:error-message="confirm_password_error_message"
							:error="confirm_password_error"
							@focus="
								confirm_password_error_message = '',
								confirm_password_error = false,
								pass_save = false
							"
						>
							<template v-slot:append>
								<q-icon
									:name="cfPwd ? 'visibility_off' : 'visibility'"
									class="cursor-pointer"
									@click="cfPwd = !cfPwd"
								/>
							</template>
						</q-input>
						<q-card-actions
							style="justify-content: center; width: 100%"
							class="q-mt-xl"
						>
							<q-btn
								push
								color="primary"
								background-color="primary"
								class="login-btn full-width"
								@click="setNewPassword"
								label="SET PASSWORD"
								:disabled="!password || !confirm_password"
							/>
						</q-card-actions>
					</q-card-section>
				</q-card>
			</div>
		</div>
	</div>
</template>

<script>
import LoginAPI from '@/services/api/Login.js'
import { mapGetters } from 'vuex'

export default {
	name: 'ChangePassword',
	meta: {
		title: 'Alert Cascade - Change Password'
	},
	data() {
		return {
			oPwd: true,
			Pwd: true,
			cfPwd: true,
			old_password: '',
			old_password_error: false,
			old_password_error_message: '',
			password: '',
			password_error: false,
			password_error_message: '',
			confirm_password: '',
			confirm_password_error: false,
			confirm_password_error_message: '',
			password_complexity_text_length: '',
			password_complexity: '',
			password_complexity_text: '',
			complexity_values: {
				0: '',
				1: [
					'one lowercase character',
					'one uppercase character'
				],
				2: [
					'one lowercase character',
					'one uppercase character', 'one number'
				],
				3: [
					'one lowercase character',
					'one uppercase character',
					'one number',
					'one special character',
				],
			},
			rules: [],
			pass_save: false,
		}
	},
	computed: {
		...mapGetters(['current_CG']),
	},
	methods: {
		setRules() {
			let rules = [
				(val) =>
					val.length >= this.password_complexity_text_length ||
					`Minimum ${this.password_complexity_text_length} characters`,
				(val) =>
					!RegExp(/\s/).test(val) || 'Must not contain whitespace characters.',
			]
			switch (this.password_complexity) {
				case 1:
					rules.push(
						(val) =>
							RegExp(/[a-z]/).test(val) ||
							'Must contain at least 1 lowercase letter.',
						(val) =>
							RegExp(/[A-Z]/).test(val) ||
							'Must contain at least 1 uppercase letter.'
					)
					break
				case 2:
					rules.push(
						(val) =>
							RegExp(/[a-z]/).test(val) ||
							'Must contain at least 1 lowercase letter.',
						(val) =>
							RegExp(/[A-Z]/).test(val) ||
							'Must contain at least 1 uppercase letter.',
						(val) =>
							RegExp(/[0-9]/).test(val) || 'Must contain at least 1 digit.'
					)
					break
				case 3:
					rules.push(
						(val) =>
							RegExp(/[a-z]/).test(val) ||
							'Must contain at least 1 lowercase letter.',
						(val) =>
							RegExp(/[A-Z]/).test(val) ||
							'Must contain at least 1 uppercase letter.',
						(val) =>
							RegExp(/[0-9]/).test(val) || 'Must contain at least 1 digit.',
						(val) =>
							RegExp(/[^\w\s]|_/).test(val) ||
							'Must contain at least 1 one special character.'
					)
					break
			}
			return rules
		},

		async setNewPassword() {
			let obj = {
				old_password: this.old_password,
				new_password1: this.password,
				new_password2: this.confirm_password,
			}

			let response = await LoginAPI.changePassword(obj)
			if (response.error) {
				let key = Object.keys(response.data)[0]
				let value = Object.values(response.data)[0]
				if (key === 'old_password') {
					this.old_password_error = true
					this.old_password_error_message = value[0]
				} else {
					this.password_error = true
					this.confirm_password_error = true
					this.password_error_message = value[0]
					this.confirm_password_error_message = value[0]
				}
				return
			}
			this.old_password = ''
			this.password = ''
			this.confirm_password = ''
			this.pass_save = true
			this.$q.notify(response.data)
		},

		redirectDashboard() {
			this.$router.back()
		},

		async passwordPolicy() {
			let response = await LoginAPI.passwordPolicy(
				this.current_CG.customer_group.root_parent_id
			)
			this.password_complexity_text_length = response.data.min_length
			this.password_complexity = response.data.complexity
			this.password_complexity_text = this.complexity_values[response.data.complexity]
		},
	},

	async created() {
		await this.passwordPolicy()
		this.rules = this.setRules()
	},
}
</script>
